.breadcrumb {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 15px;
    margin-top:30px;
    background-color: white;
    padding: 10px 15px;
    border-radius: 4px;
    box-shadow: 0 .46875rem 2.1875rem rgba(4,9,20,.03),0 .9375rem 1.40625rem rgba(4,9,20,.03),0 .25rem .53125rem rgba(4,9,20,.05),0 .125rem .1875rem rgba(4,9,20,.03);
    
    .breadcrumb-item {
        font-size: 14px;
        font-weight: 300;
        color: #333;
        margin-right: 10px;

        a {
            color: #333;
            text-decoration: none;

            &:hover {
                text-decoration: underline;
            }
        }
    }
}