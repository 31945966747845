header {
    background: #ffffff;
    color: black;
    height: 50px;
    width: 100%;
    position:fixed;
    top: 0;
    box-shadow: 0 .46875rem 2.1875rem rgba(4,9,20,.03),0 .9375rem 1.40625rem rgba(4,9,20,.03),0 .25rem .53125rem rgba(4,9,20,.05),0 .125rem .1875rem rgba(4,9,20,.03);
    z-index: var(--header-z-index);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 35px;

    h1 {
        margin: 0;
        padding: 0 20px;
        line-height: 50px;
        font-size: 20px;
    }

    .flag-icon {
        height: 20px;
        width: 20px;
    }
}