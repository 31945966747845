
.list-action {
    display:flex;
    margin-top:30px;

    div {
        margin-right:15px;
        text-decoration: none;
    }
}

.contents-page {
    display: flex;

    .table {
        flex:9 9;
    }

    .form-page {
        flex: 3 3;
        background-color: white;
        margin-left: 30px;
        margin-top: 30px;
        padding: 15px;
        border-radius: 5px;
        border-color: rgba(224, 224, 224, 1);

        form {
            margin-top: 10px;
        }
    }
}