.add-img-from-gallery {
    width: 100%;

    .upload-img img {
        display: block;
        width: 100%;
    }

    > label {
        display: block;
        word-break: break-all;
        margin: 5px 10px;

        &.title {
            font-weight: 500;
        }
    }

    > button {
        margin: 5px 10px;
    }
}