form {
    margin-top:30px;

    .panel {
        display: flex;
        flex-direction: row;

        .contents {
            width: 80%;
            background-color: #fff;
            box-shadow: 0 .46875rem 2.1875rem rgba(4,9,20,.03),0 .9375rem 1.40625rem rgba(4,9,20,.03),0 .25rem .53125rem rgba(4,9,20,.05),0 .125rem .1875rem rgba(4,9,20,.03);
            padding: 30px 20px;

            > *:first-child {
                margin-top: 0;
            }
        }

        .react-tabs {
            width: 80%;

            .contents {
                width: 100%;
            }

            & + .aside-right {
                margin-top: 34px;
            }
        }
        
        .aside-right {
            width: 20%;
            margin-left: 30px;
            background-color: #fff;
            box-shadow: 0 .46875rem 2.1875rem rgba(4,9,20,.03),0 .9375rem 1.40625rem rgba(4,9,20,.03),0 .25rem .53125rem rgba(4,9,20,.05),0 .125rem .1875rem rgba(4,9,20,.03);
            padding: 30px 20px;

            > *:first-child {
                margin-top: 0;
            }

            hr {
                background-color: rgba(0, 0, 0, 0.23);
                height: 1px;
                border: none;
                margin-bottom: 2rem;
            }
        }

        &.auction {
            .aside-right {
                width: 23%;
            }

            .contents, .react-tabs {
                width: 77%;

                .contents {
                    width: 100%;
                }
            }
        }
        
    }

    input[type="submit"] {
        border: none;
        border-radius: 4px;
        background-color: var(--primary);
        color:white;
        font-size: 14px;
        cursor: pointer;
        transition: all 0.3s ease-in-out;
        
        &:hover {
            background-color: #21a5f1;
        }
    }

    .error-msg, span.error {
        color: red;
        font-size: 14px;
    }
}

.row-form {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 1rem 0 1.5rem;
    gap: 15px;

    &:first-child {
        margin-top: 0;
    }

    .form-group {
        width: 100%;
        margin-bottom: 0;
        // margin-right:15px;

        label, label .MuiFormControlLabel-label {
            font-size: 15px;
            font-weight: 300;
            color: #333;
        }

        input:not(.MuiInputBase-input) {
            width: 100%;
            height: 35px;
            // padding-left: 10px;
            // margin-top: 10px;
        }

        input[type='checkbox'] {
            width: 20px;
            height:20px;
            margin-right: 5px;
        }

        input[type="file"] {
            width: 100%;
            height:35px;
            padding-top:7px;
        }

        textarea {
            width: 100%;
            // min-height: 300px;
            padding: 10px;
            margin-top: 10px;
            resize: none;
        }

        select {
            width: 100%;
            height: 35px;
            padding-left: 10px;
            margin-top: 10px;
        }

        .ck.ck-editor {
            width: 100%;
            margin-top: 10px;
        }

        .error .ck.ck-editor {
            border: 1px solid red;
        }

        .ck-blurred, .ck-content.ck-editor__editable_inline {
            min-height: 200px;
            max-height: 350px;
        }
    }
}

.upload-img {
    max-width: 100%;
    width: 100%;
    position: relative;

    img {
        max-width: calc(100% - 20px);
        margin: 10px;
        background-color: rgba(0, 0, 50, 0.15);
    }

    .remove-media {
        content: "\00D7";
        background-color: var(--primary);
        $size: 30px;
        width: $size;
        height: $size;
        border-radius: 50%;
        color: white;
        display: none;
        text-align: center;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        font-weight: bold;
        font-size: 20px;
        position: absolute;
        top: calc(-#{$size}/2 + 10px);
        right: calc(-#{$size}/2 + 10px);
        animation: popIn .2s;

        @keyframes popIn {
            from { transform: scale(0); }
            to { transform: scale(1); }
        }
    }

    &:hover .remove-media {
        display: flex;
    }
}

.btn-form {
    display: flex;
    gap: 15px;
}

.form-group.button {
    .label-container {
        display: flex;
        align-items: center;

        label {
            margin-right: 15px;
            margin-bottom: 13px;
        }
    }
}