.navigation {
    background-color: white;
    min-height: 100vh;
    width: 250px;
    position:fixed;
    top: 50px;

    .list-item {
        color: var(--grey);

        svg {
            color: var(--grey);
        }
        
        &:hover, &.active {
            color: var(--primary);

            svg {
                color: var(--primary);
            }
        }

        span {
            font-family: 'Poppins', Poppins Light, Poppins Regular, Poppins SemiBold, Poppins ExtraBold !important;
        }
    }

    ul {
        li {
            margin-bottom:5px;
            padding: 7px 20px;
            font-size: 15px;
            cursor: pointer;

            a {

                svg {
                    margin-right: 10px;
                    width: 20px;
                }

                color: #7f7f7f;
                display: block;
                text-decoration: none;
                font-weight: 500;
                font-size: 15px;

                
            }

            &:hover {
                color: var(--primary);
            }

            .arrowDown {
                float: right;
                content: "<";
                font-size: 20px;
                width: 13px;
                height:13px;
                transform: rotate(-90deg);
            }

            .active {
                color: var(--primary);
            }

            ul {
                margin-top: 0;
                margin-left: 20px;
                list-style-type: none;

                li {
                    margin-bottom: 0;

                    padding-left:10px;
                    a {
                        color: #7f7f7f;
                        font-size: 13px;
                        font-weight: 400;
                        &:hover {
                            color: var(--primary);
                        }
                    }
                    .active {
                        color: var(--primary);
                    }
                }

            }

            svg {
                margin-right: 10px;
                width: 20px;
            }
        }
    }
}