.header-list {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 15px;
    margin-top:30px;
    align-items: center;

    .title {
        display: flex;

        .icon {
            background-color: white;
            box-shadow: 0 .46875rem 2.1875rem rgba(4,9,20,.03),0 .9375rem 1.40625rem rgba(4,9,20,.03),0 .25rem .53125rem rgba(4,9,20,.05),0 .125rem .1875rem rgba(4,9,20,.03);
            font-size: 2rem;
            display: flex;
            align-items: center;
            align-content: center;
            text-align: center;
            padding: .83333rem;
            margin: 0 20px 0 0;
            border-radius: 5px;

            svg {
                height: 25px;
                color: #585858;
                opacity: 0.8;
            }
        }

        h1 {
            font-size: 22px;
            font-weight: 200;
            color: #000000;
            margin-top: 8px;
            font-weight: bold;
        }
    }

    .btn {
        text-decoration: none;
    }

    .buttons {
        display: flex;
        align-items: center;
        gap: 15px;
    }
}