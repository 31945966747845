.visionneuse {
    position: fixed;
    top: 0; right: 0; left: 0; bottom: 0;
    width: 100%;
    height: 100vh;
    z-index: var(--popup-z-index);
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, .5);

    #visionneuse-img {
        max-width: 90vw;
        max-height: 90vh;
        animation: popUp ease-out .3s;
    }

    @keyframes popUp {
        from { transform: scale(0) }
        to { transform: scale(1) }
    }

    .visionneuse-arrow {
        filter: invert(38%) sepia(73%) saturate(3679%) hue-rotate(342deg) brightness(107%) contrast(102%);
        height: 75px;
        cursor: pointer;
        position: fixed;
        z-index: calc( var(--popup-z-index) + 1 );

        &.prev {
            left: 25px;
        }

        &.next {
            transform: rotate(180deg);
            right: 25px;
        }

        &[disabled] {
            opacity: .4;
        }
    }

    .visionneuse-close {
        filter: invert(38%) sepia(73%) saturate(3679%) hue-rotate(342deg) brightness(107%) contrast(102%);
        height: 30px;
        cursor: pointer;
        position: fixed;
        z-index: calc( var(--popup-z-index) + 1 );
        top: 20px;
        right: 20px;
    }

    @media screen and (max-width: 768px) {
        #visionneuse-img {
            max-height: calc(100vh - (50px + 20px*2)*2);
        }

        .visionneuse-arrow {
            height: 50px;
            bottom: 20px;
        }
    }
}