@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('assets/scss/table.scss');
@import url('assets/scss/form.scss');

:root {
    --primary: #1976d2;
    --grey: #7f7f7f;

    --popup-z-index: 10;
    --header-z-index: 9;
}

* {
    font-family: 'Poppins', Poppins Light, Poppins Regular, Poppins SemiBold, Poppins ExtraBold;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html, body {
    background-color: #ebeef0;
}

.container {
    display: flex;
}

.list {
    margin-bottom:100px;
}

.content-wrapper {
    width: 100%;
    padding-top: 60px;
    padding-left:280px;
    padding-right:40px;

    .content {
        width: 100%;
        margin-bottom:100px;

        h1 {
            font-size: 24px;
            font-weight: 300;
            color: #333;
            margin-top: 20px;
        }

    }

    
}

.notification-container {
    top: 50px !important;
}

.contents-page {
    width: 100%;

    form {
        width: 100%;
    }
}

 /* The switch - the box around the slider */
 .switch {
     position: relative;
     display: inline-block;
     width: 42px;
     height: 23px;
 }

 /* Hide default HTML checkbox */
 .switch input {
     opacity: 0;
     width: 0;
     height: 0;
 }

 /* The slider */
 .slider {
     position: absolute;
     cursor: pointer;
     top: 0;
     left: 0;
     right: 0;
     bottom: 0;
     background-color: #ccc;
     -webkit-transition: .4s;
     transition: .4s;
 }

 .slider:before {
     position: absolute;
     content: "";
     height: 15px;
     width: 15px;
     left: 4px;
     bottom: 4px;
     background-color: white;
     -webkit-transition: .4s;
     transition: .4s;
 }

 input:checked+.slider {
     background-color: var(--primary);
 }

 input:focus+.slider {
     box-shadow: 0 0 1px var(--primary);
 }

 input:checked+.slider:before {
     -webkit-transform: translateX(20px);
     -ms-transform: translateX(20px);
     transform: translateX(20px);
 }

 /* Rounded sliders */
 .slider.round {
     border-radius: 34px;
 }

 .slider.round:before {
     border-radius: 50%;
 }


 .btn {
    display: flex;
    align-items: center;
    padding: 0 10px;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 300;
    transition: all 0.3s ease-in-out;
    height:40px;
    cursor: pointer;

    a {
        color: white;
        text-decoration: none;
    }

    svg {
        height: 15px;
        margin: 0 5px 0 0;
    }
}

.btn-add {
    background-color: var(--primary);
    color: white;

    &:hover {
        color: white;
        background-color: var(--primary);
    }
}

// REACT TABS
.react-tabs__tab-list {
    border-bottom:none !important;
    margin:0px !important;
}

.react-tabs__tab--selected {
    border:none !important;
    color:white !important;
    background-color: var(--primary) !important;
}

.react-tabs {
    .react-tabs__tab-list {
        li {
            font-weight:300;
            font-size:14px;

            .error-pastille {
                $size: 10px;
                width: $size;
                height: $size;
                background-color: red;
                border-radius: 50%;
                display: inline-block;
                margin-left: 8px;
            }

            &.react-tabs__tab--selected .error-pastille {
                background-color: white;
            }
        }
    }
}


// MEDIA UPLOAD

.media-upload {

    .media-list {
        display: flex;
        gap: 5px;
        margin: 15px -10px -10px;

        .upload-media {
            position: relative;
            padding: 10px;
            
            img {
                $size: 250px;
                width: $size;
                height: $size;
                object-fit: cover;
                object-position: center;
            }

            .remove-media {
                content: "\00D7";
                background-color: var(--primary);
                $size: 30px;
                width: $size;
                height: $size;
                border-radius: 50%;
                color: white;
                display: none;
                text-align: center;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                font-weight: bold;
                font-size: 20px;
                position: absolute;
                top: calc(-#{$size}/2 + 10px);
                right: calc(-#{$size}/2 + 10px);
                animation: popIn .2s;

                @keyframes popIn {
                    from { transform: scale(0); }
                    to { transform: scale(1); }
                }
            }

            &:hover .remove-media {
                display: flex;
            }
        }
    }
}



.MuiDataGrid-row {
    a {
        color: var(--primary);
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }
}


.plusBtn {
    padding: 0 12px;
    background-color: var(--primary);
    border-radius: 5px;
    color: white;
    appearance: none;
    outline: none;
    border: none;
    font-size: 25px;
    font-weight: 500;
    cursor: pointer;
}