.popup {
    position: fixed;
    top: 0; right: 0; left: 0; bottom: 0;
    width: 100vw; height: 100vh;
    z-index: var(--popup-z-index);
    display: flex;
    justify-content: center;
    align-items: center;
    animation: fadeIn .15s;
    background-color: rgba(0, 0, 0, .5);

    &.top {
        align-items: flex-start;
        padding-top: 2.5vh;
    }

    .popup-container {
        max-width: 90vw; min-width: 500px;
        max-height: 95vh;
        animation: slideDownFade .45s;
        background-color: white;
        position: relative;
        overflow: auto;
        border-radius: 5px;

        @media screen and (max-width: 1024px) {
            min-width: 90vw;
        }        

        h4 {
            padding: 1rem;
            padding-right: 3rem;
            border-bottom: 1px solid #dee2e6;
            font-weight: 500;
            font-size: 18px;
        }

        .popup-content {
            padding: 1rem;
            line-height: 1.5;
        }

        .closePopupIcon {
            position: absolute;
            top: 0;
            right: 5px;
            cursor: pointer;
            padding: 1rem;
            opacity: .5;

            img {
                $size: 10px;
                width: $size;
                height: $size;
            }

            &:hover { opacity: .75 }
        }
    }

    &.closed {
        opacity: 0;
        transition: opacity .15s;
        transition-delay: .3s;

        .popup-container {
            opacity: 0;
            transform: translateY(-50px);
            transition: all .3s;
        }
    }

    // OPEN
    @keyframes fadeIn {
        from { opacity: 0 }
        to { opacity: 1 }
    }

    @keyframes slideDownFade {
        0% { opacity: 0; transform: translateY(-50px); }
        33% { opacity: 0; transform: translateY(-50px); }
        100% { opacity: 1; transform: translateY(0px); }
    }


    // CUSTOM CONTENT

    .popupCreateHorseQuick, .popupCustom {
        padding: 1rem;
        margin-top: 0;

        > * + *:not(.MuiPopper-root) { margin-top: 1.5rem }

        .btn-container {
            display: flex;
            justify-content: flex-end;
            gap: 15px;

            .cancel:hover {
                text-decoration: underline;
            }
        }

        .form {
            min-height: 175px;
        }
    }

    .popupAddMedia {
        aside {
            .overlay {
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                justify-content: center;
                align-items: center;
                background-color: rgba(0, 0, 0, 0.5);
                display: none;
            }
            .trash-icon {
                color: white;
            }
            .thumb:hover .overlay {
                display: flex;
            }
        }
    }
}