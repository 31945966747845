.login {
    width: 100%;
    padding: 7rem 7vw 7rem;

    .login-header {
        display: flex;
        justify-content: space-between;
        max-width: 1140px;
        margin: auto;
        margin-bottom: 3.75rem;
        padding: 0 15px 2.5rem;
        border-bottom: 1px solid #dee2e6;
        align-items: flex-end;

        h1 {
            font-size: 40px;
            font-weight: bold;
            line-height: 1.2;
            letter-spacing: .5px;
            white-space: nowrap;
            text-align: center;
            display: block;
            width: 100%;
        }
    }

    .register-form {
        max-width: 525px;
        padding: 0 10px;
        margin: auto;
        margin-top: 3.75rem;

        > * + * {
            margin-top: 25px;
        }

        button {
            width: 100%;
        }

        .MuiInputBase-input  {
            background: white;
        }
    }

    .error-msg {
        background-color: var(--orange);
        color: red;
        padding: 1.2rem 1rem;
    }
}