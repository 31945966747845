.dashboard {
    .page-content {
        background-color: white;
        padding: 40px 30px;
        margin-top: 25px;

        h2 {
            font-size: 18px;
            margin-bottom: 25px;
        }
    }
}